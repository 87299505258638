<ng-template [ngIf]="isloading == false" [ngIfElse]="loading">
  <div class="card card-branch m-1">
    <div class="card-body px-0 pt-0">
      <div class="card-image-block w-auto overflow-hidden">
        <a class="text-decoration" [routerLink]="['/chi-nhanh', branch.slug]">
          <img class="card-branch-image" [src]="branch.image[0]" loading="lazy"
            onerror="this.onerror=null;this.src='https://res.cloudinary.com/tnvinh00/image/upload/v1639495889/PhoOcChuNam/QJzMcF1_n42ptl.png'"
            alt="Card image cap">
        </a>
      </div>
      <a class="text-decoration" [routerLink]="['/chi-nhanh', branch.slug]">
        <p class="card-branch-title px-1 pt-4 mb-0 text-center">{{branch.name}}</p>
      </a>
      <div class="px-3 pt-2 text-center">
        <p class="card-branch-address">{{branch.address}}</p>
        <div class="card-branch-phone">
          <span>{{'const.phone2' | translate}}{{": "}}</span>
          <span *ngFor="let p of branch.phone">
            <a href="tel:{{p}}">{{p}} </a>
          </span>
        </div>
        <div class="card-branch-fanpage mb-3">
          <span>{{'const.fanpage' | translate}}{{": "}}</span>
          <a href="{{branch.link}}" target="_blank">{{branch.fanpage}}</a>
        </div>
        <app-round-button [text]="'action.direction' | translate" [icon]="'near_me'" [height]="'45px'"
          (click)="onDirection(branch.googlemap)"></app-round-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="card card-branch m-1">
    <div class="card-body px-0 pt-0 w-100">
      <div class="card-image-block w-auto overflow-hidden">
        <ngx-skeleton-loader count="1"
          [theme]="{ 'border-radius': '10px 10px 0 0', 'margin-bottom': '25px', width: '400px', height: '200px'}"
          appearance="line">
        </ngx-skeleton-loader>
      </div>
      <div class="px-2 pt-2">
        <ngx-skeleton-loader count="1" appearance="line">
        </ngx-skeleton-loader>
      </div>
      <div class="px-5 text-center">
        <ngx-skeleton-loader count="1" appearance="line">
        </ngx-skeleton-loader>
        <div class="card-branch-phone px-5">
          <ngx-skeleton-loader count="1" appearance="line">
          </ngx-skeleton-loader>
        </div>
        <div class="card-branch-fanpage mb-3">
          <ngx-skeleton-loader count="1" appearance="line">
          </ngx-skeleton-loader>
        </div>
        <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '25px', 'margin-bottom': '25px', height: '45px'}"
          appearance="line">
        </ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>