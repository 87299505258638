<header class="header bg-light">
  <div class="container my-container">
    <div class="header-container">
      <a class="header-logo h2 m-0" routerLink="/">
        <img src="assets/images/logo/logo.png" class="pulse-shrink" alt="{{'name' | translate}}">
      </a>
      <ul class="menu" [ngClass]="{'show' : isshow}">
        <li class="menu-item">
          <a routerLink="/" (click)="onClickMenu('')" class="menu-item-link" [ngClass]="{'active': activeLink == ''}">{{'menu.title1' | translate}}</a>
        </li>
        <li class="menu-item">
          <a routerLink="/menu" (click)="onClickMenu('menu')" class="menu-item-link" [ngClass]="{'active': activeLink == 'menu'}">{{'menu.title2' | translate}}</a>
        </li>
        <li class="menu-item">
          <a routerLink="/chi-nhanh" (click)="onClickMenu('chi-nhanh')" type="button" class="menu-item-link" [ngClass]="{'active': activeLink == 'chi-nhanh'}">{{'menu.title3' | translate}}</a>
        </li>
        <li class="menu-item px-3">
          <button mat-raised-button class="menu-item-button" color="'#F44336'" (click)="gotoContacts()">
            <span class="button-text">{{'menu.title4' | translate}}</span> {{" "}}
            <mat-icon>phone_enabled</mat-icon>
          </button>
        </li>
      </ul>
      <div class="menu-toggle">
        <button mat-icon-button color="warn" (click)="onClickOpenMenu()">
          <mat-icon>{{icon}}</mat-icon>
        </button>
      </div>
    </div>
  </div>
</header>