<!-- Footer -->
<footer class="footer" id="footer-about">
  <div class="container pt-4">
    <div class="row py-3 mb-3">
      <div class="col-12 col-md-6 col-lg-3">
        <div class="w-100 d-flex justify-content-center">
          <img src="assets/images/logo/logo_light.png" width="200" class="img-fluid">
        </div>
        <div class="col-12 mt-4 d-flex justify-content-center align-items-center">
          <span>{{'footer.language.select' | translate}}</span>
          <select class="ml-2 form-control form-control-sm col-6" #langSelect
            (change)="onChangeLanguage(langSelect.value)">
            <option [selected]="currentLanguage == 'vi'" value="vi"
              style="background-image:url(assets/images/icon/vn.png);">
              <span>{{'footer.language.vn.name' | translate}}</span>
            </option>
            <option [selected]="currentLanguage == 'en'" value="en">
              <span>{{'footer.language.en.name' | translate}}</span>
            </option>
            <!-- <option [selected]="currentLanguage == 'zh'" value="zh">
              <span>{{'footer.language.cn.name' | translate}}</span>
            </option> -->
          </select>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 footer-info mt-3">
        <p>{{'caption' | translate}}</p>
        <h3>{{'name' | translate}}</h3>
        <p>Website: <a href="https://phoocchunam.com">phoocchunam.com</a></p>
        <p>Email: <a href="mailto:phoocchunam@gmail.com">phoocchunam@gmail.com</a></p>
        <p>Hotline: <a href="tel:0362596790">0362596790</a></p>
      </div>
      <div class="col-12 col-md-8 col-lg-5 mt-sm-3">
        <h6 class="text-uppercase text-book font-weight-bold mb-4">{{'const.book' | translate}}</h6>
        <ul class="list-footer mb-0">
          <li class="mb-2">
            {{'footer.branch1.title' | translate}} {{'footer.branch1.description' | translate}}
            <a class="text-white" href="tel:{{'footer.branch1.contact' | translate}}">
              {{'footer.branch1.contact' | translate}}
            </a>
          </li>
          <li class="mb-2">
            {{'footer.branch2.title' | translate}} {{'footer.branch2.description' | translate}}
            <a class="text-white" href="tel:{{'footer.branch2.contact' | translate}}">
              {{'footer.branch2.contact' | translate}}
            </a>
          </li>
          <li class="mb-2">
            {{'footer.branch3.title' | translate}} {{'footer.branch3.description' | translate}}
            <a class="text-white" href="tel:{{'footer.branch3.contact' | translate}}">
              {{'footer.branch3.contact' | translate}}
            </a>
          </li>
          <li class="mb-2">
            {{'footer.branch6.title' | translate}} {{'footer.branch6.description' | translate}}
            <a class="text-white" href="tel:{{'footer.branch6.contact' | translate}}">
              {{'footer.branch6.contact' | translate}}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <hr class="m-0 bg-white" />
    <div class="row">
      <div class="col-12">
        <p class="text-white text-small text-center mb-0 py-3 ">
          &copy;2021 All rights reserved.
          <span class="align-baseline">
            {{ 'const.developer' | translate }}
            <a class="text-white" href="mailto:tnvinh99@gmail.com">tnvinh00</a>
          </span>
        </p>
      </div>
    </div>
  </div>
</footer>
<!-- End -->

<!-- Phone -->
<div class="quick-alo-phone quick-alo-green quick-alo-show" id="quick-alo-phoneIcon">
  <a href="tel:0362596790">
    <div class="quick-alo-ph-circle"></div>
    <div class="quick-alo-ph-circle-fill"></div>
    <div class="quick-alo-ph-img-circle" matTooltip="{{'menupage.tooltip' | translate}}">
      <mat-icon>phone_enabled</mat-icon>
    </div>
  </a>
</div>