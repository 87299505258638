<app-top-bar></app-top-bar>

<div class="main">
  <div class="wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
<app-footer></app-footer>


<app-scroll-top-button></app-scroll-top-button>