<div>Some tags before</div>
<div class="carousel-main">

  <owl-carousel-o [options]="customOptions">
    <ng-template carouselSlide>Slide 1</ng-template>
    <ng-template carouselSlide>Slide 2</ng-template>
    <ng-template carouselSlide>Slide 3</ng-template>
    <ng-template carouselSlide>Slide 4</ng-template>
    <ng-template carouselSlide>Slide 5</ng-template>
    <ng-template carouselSlide>Slide 6</ng-template>
    <ng-template carouselSlide>Slide 7</ng-template>
    <ng-template carouselSlide>Slide 8</ng-template>
    <ng-template carouselSlide>Slide 9</ng-template>
    <ng-template carouselSlide>Slide 10</ng-template>
  </owl-carousel-o>
</div>
<div>Some tags after</div>