<app-top-carousel></app-top-carousel>
<!-- <app-carousel></app-carousel> -->

<app-block-feature></app-block-feature>

<p class="section-header">{{ 'homeheader.latest_posts' | translate }}</p>
<app-fb-posts></app-fb-posts>

<!-- Section seafood -->
<p class="section-header">{{ 'homeheader.seafood' | translate }}</p>
<ng-template [ngIf]="category.length > 0" [ngIfElse]="loading">
  <div class="container general-section">
    <div class="w-100">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let item of category">
          <div class="py-3">
            <div class="card card-category mx-2 mx-sm-1">
              <div class="card-body">
                <div class="w-auto overflow-hidden border rounded rounded-lg">
                  <img class="card-image" [src]="item.image" loading="lazy"
                    onerror="this.onerror=null;this.src='https://res.cloudinary.com/tnvinh00/image/upload/v1639812672/PhoOcChuNam/image-temp_vcczhz.png'"
                    alt="Card image cap">
                </div>
                <div class="card-content">
                  <p class="card-title-text text-seafood" matTooltip={{item.title}}>{{item.title}}</p>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="container general-section">
    <div class="w-100">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let i of [1,2,3,4,5]">
          <div class="py-3">
            <div class="card card-category m-1 mx-sm-1">
              <div class="card-body">
                <ngx-skeleton-loader count="1"
                  [theme]="{ 'border-radius': '10px', 'margin-bottom': '25px', height: '150px'}" appearance="line">
                </ngx-skeleton-loader>
                <div class="px-5">
                  <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</ng-template>

<!-- Section seafood Special-->
<p class="section-header text-main">{{ 'homeheader.seafood_special' | translate }}</p>
<p class="section-subtile"><i>{{ 'const.special' | translate }}</i></p>
<ng-template [ngIf]="category.length > 0" [ngIfElse]="loading">
  <div class="container general-section">
    <div class="w-100">
      <owl-carousel-o [options]="customOptions_special">
        <ng-template carouselSlide *ngFor="let item of category_special">
          <div class="py-3">
            <div class="card card-category mx-2 mx-sm-1 border-main">
              <div class="card-body">
                <div class="w-auto overflow-hidden border rounded rounded-lg">
                  <img class="card-image" [src]="item.image" loading="lazy"
                    onerror="this.onerror=null;this.src='https://res.cloudinary.com/tnvinh00/image/upload/v1639812672/PhoOcChuNam/image-temp_vcczhz.png'"
                    alt="Card image cap">
                </div>
                <div class="card-content">
                  <p class="card-title-text text-seafood" matTooltip={{item.title}}>{{item.title}}</p>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="container general-section">
    <div class="w-100">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let i of [1,2,3,4,5]">
          <div class="py-3">
            <div class="card card-category m-1 mx-sm-1">
              <div class="card-body">
                <ngx-skeleton-loader count="1"
                  [theme]="{ 'border-radius': '10px', 'margin-bottom': '25px', height: '150px'}" appearance="line">
                </ngx-skeleton-loader>
                <div class="px-5">
                  <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</ng-template>

<!-- Section trend food -->
<p class="section-header">{{ 'homeheader.trend' | translate }}</p>
<p class="section-subtile">{{ 'const.special_short' | translate }}</p>
<ng-template [ngIf]="category.length > 0" [ngIfElse]="loading2">
  <div class="container general-section">
    <div class="w-100">
      <owl-carousel-o [options]="customOptions_food">
        <ng-template carouselSlide *ngFor="let item of food_odd; let i = index;">
          <div class="pt-2 pb-1">
            <div class="card card-trend m-1 m-sm-1" title="{{'menupage.tooltip' | translate}}">
              <div class="card-body">
                <div class="card-food-overlay d-flex justify-content-between position-absolute w-100" *ngIf="item.note">
                  <div class="bg-main d-flex align-items-center px-3 py-0">*</div>
                </div>
                <div class="card-image-block w-auto overflow-hidden">
                  <img class="card-image" [src]="item.image" loading="lazy"
                    onerror="this.onerror=null;this.src='https://res.cloudinary.com/tnvinh00/image/upload/v1639812672/PhoOcChuNam/image-temp_vcczhz.png'"
                    alt="Card image cap">
                </div>
                <div class="card-content px-2">
                  <p class="card-title-text text-food px-1">{{item.title}}</p>
                </div>
                <hr class="card-divider" />
                <div class="card-price-text">
                  <span>{{'const.price' | translate}}</span>
                  <span class="card-price-text-call">{{'const.contact' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="pb-2">
            <div class="card card-trend m-1 m-sm-1" title="{{'menupage.tooltip' | translate}}">
              <div class="card-body">
                <div class="card-food-overlay d-flex justify-content-between position-absolute w-100"
                  *ngIf="food_even[i].note">
                  <div class="bg-main d-flex align-items-center px-3 py-0">*</div>
                </div>
                <div class="card-image-block w-auto overflow-hidden">
                  <img class="card-image" [src]="food_even[i].image" loading="lazy"
                    onerror="this.onerror=null;this.src='https://res.cloudinary.com/tnvinh00/image/upload/v1639812672/PhoOcChuNam/image-temp_vcczhz.png'"
                    alt="Card image cap">
                </div>
                <div class="card-content px-2">
                  <p class="card-title-text text-food">{{food_even[i].title}}</p>
                </div>
                <hr class="card-divider" />
                <div class="card-price-text">
                  <span>{{'const.price' | translate}}</span>
                  <span class="card-price-text-call">{{'const.contact' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</ng-template>

<ng-template #loading2>
  <div class="container general-section">
    <div class="w-100">
      <owl-carousel-o [options]="customOptions_food">
        <ng-template carouselSlide *ngFor="let i of [1,2,3,4,5]">
          <div class="pt-2 pb-1">
            <div class="card card-trend m-1 m-sm-1">
              <div class="card-body">
                <ngx-skeleton-loader count="1"
                  [theme]="{ 'border-radius': '10px 10px 0 0', 'margin-bottom': '25px', height: '150px'}"
                  appearance="line">
                </ngx-skeleton-loader>
                <div class="px-5">
                  <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 'margin-bottom': '25px'}">
                  </ngx-skeleton-loader>
                  <hr class="card-divider" />
                  <ngx-skeleton-loader count="1" appearance="line">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
          <div class="pb-2">
            <div class="card card-trend m-1 m-sm-1">
              <div class="card-body">
                <ngx-skeleton-loader count="1"
                  [theme]="{ 'border-radius': '10px 10px 0 0', 'margin-bottom': '25px', height: '150px'}"
                  appearance="line">
                </ngx-skeleton-loader>
                <div class="px-5">
                  <ngx-skeleton-loader count="1" appearance="line">
                  </ngx-skeleton-loader>
                  <hr class="card-divider" />
                  <ngx-skeleton-loader count="1" appearance="line">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</ng-template>

<!-- Section branchs -->
<app-block-contact></app-block-contact>